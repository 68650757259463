import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { SmallLoader } from '@/components/ui/custom-ui/loading'
import { IntercomMetadata, trackIntercomEvent } from '@/utils/intercom'
import { useCurrentCompanyId } from '@/hooks/generic/processing-run-hooks'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-0 mx-0 m-0 text-zinc-800 underline-offset-4 hover:underline p-0  text-left',
  {
    variants: {
      variant: {
        default: 'bg-zinc-900 text-white hover:bg-zinc-900/90',
        destructive: 'bg-red-500 text-slate-50 hover:bg-red-500/90',
        outline: 'border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900',
        primary: 'bg-orange-500 hover:bg-orange-500/80',
        white: 'bg-slate-100 text-slate-900 hover:bg-slate-100/80',
        secondary: 'bg-slate-100 text-slate-900 hover:bg-slate-100/80',
        cta: 'text-lg bg-slate-100 text-slate-900 hover:bg-slate-100/80',
        ghost: 'hover:bg-slate-100 hover:text-slate-900',
        navGhost:
          'hover:bg-slate-100 hover:text-slate-900 py-1 rounded-none w-full text-left mr-auto justify-left',
        link: 'text-slate-900 underline-offset-4 hover:underline',
        neutralLink: 'text-zinc-800 underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-7 rounded-sm px-2 text-xs',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8 text-md',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  analyticsKey: string
  analyticsProperties: IntercomMetadata
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    // const [companyId] = useCurrentCompanyId()

    const propsClone = { ...props }

    // @ts-ignore
    delete propsClone.analyticsKey
    // @ts-ignore
    delete propsClone.analyticsProperties
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...propsClone}
        onClick={(evt) => {
          trackIntercomEvent({
            eventName: `${props.analyticsKey}_button_click`,
            metadata: props.analyticsProperties,
          })

          if (props.onClick) {
            props.onClick(evt as any)
          }
        }}
      >
        {props.loading ? <SmallLoader /> : props.children}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
